var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "LBLBASEINFO" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: {
                    disabled: _vm.popupParam.isOld,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  on: { datachange: _vm.datachange },
                  model: {
                    value: _vm.popupParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.popupParam, "plantCd", $$v)
                    },
                    expression: "popupParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    disabled: _vm.popupParam.isOld,
                    editable: _vm.editable,
                    type: "year",
                    label: "년도",
                    name: "year",
                  },
                  on: { datachange: _vm.datachange },
                  model: {
                    value: _vm.popupParam.year,
                    callback: function ($$v) {
                      _vm.$set(_vm.popupParam, "year", $$v)
                    },
                    expression: "popupParam.year",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "inout-table",
          staticClass: "q-mt-sm",
          attrs: {
            title: `${_vm.popupParam.year}년도 제조/수입/사용/판매 관리대장`,
            editable: _vm.editable,
            columns: _vm.gridColumns,
            data: _vm.grid.data,
            gridHeight: _vm.gridHeight,
            filtering: false,
            columnSetting: false,
            usePaging: false,
          },
          scopedSlots: _vm._u([
            {
              key: "customBottomRow",
              fn: function () {
                return [
                  _vm.grid.data && _vm.grid.data.length > 0
                    ? _c(
                        "q-tr",
                        {
                          staticStyle: {
                            "background-color": "lightgray",
                            position: "sticky",
                            bottom: "0",
                          },
                          attrs: { "no-hover": "" },
                        },
                        [
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-right th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        _vm.$_.round(
                                          _vm.$_.sumBy(
                                            _vm.grid.data,
                                            "carryover"
                                          ),
                                          2
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-right th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        _vm.$_.round(
                                          _vm.$_.sumBy(
                                            _vm.grid.data,
                                            "inAmount"
                                          ),
                                          2
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-right th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        _vm.$_.round(
                                          _vm.$_.sumBy(
                                            _vm.grid.data,
                                            "outAmount"
                                          ),
                                          2
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "q-td",
                            {
                              staticClass: "text-right th-td-style th-style",
                              attrs: { rowspan: 1, colspan: 1 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        _vm.$_.round(
                                          _vm.$_.sumBy(
                                            _vm.grid.data,
                                            "inventory"
                                          ),
                                          2
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c("q-td", {
                            staticClass: "text-center th-td-style th-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.printFlag
                    ? _c("c-btn", {
                        attrs: {
                          label: "특별관리물질 관리대장 출력",
                          icon: "print",
                        },
                        on: {
                          btnClicked: function ($event) {
                            return _vm.print("S")
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.printFlag
                    ? _c("c-btn", {
                        attrs: {
                          label: "유해물질 관리대장 출력",
                          icon: "print",
                        },
                        on: {
                          btnClicked: function ($event) {
                            return _vm.print("H")
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          label: "배출량 조사대상 화학물질 엑셀 업로드",
                          icon: "note_add",
                        },
                        on: { btnClicked: _vm.openExcelUploader },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }